import React, { useEffect, useState } from "react";
import styles from "./HomePage.module.scss";
import { Nav } from "react-bootstrap";
import Header from "../../components/Header/Header";
import vector from "../../assets/images/Vector1.png";

const policyObj = {
  Submission_Portal: {
    mainContent:
      "Rates displayed on indications are carrier manual rates only and do not factor in policy-specific pricing components. Calculated indications are not bindable quotes. An appropriate submission is required.",
    SubHeading: "Submission Portal - SAIC",
    Links: {
      "ACORD 130(FL) Fillable_Blank":
        "https://risktransfer.egnyte.com/dl/r5IosCI0aT",
      "ACORD 130 Fillable_Blank":
        "https://risktransfer.egnyte.com/dl/f9G7rBfjck",
      "New PEO Business Submission Requirements":
        "https://risktransfer.egnyte.com/dl/3dMTraUXBr",
      "PEO Policy Type_Regulations by State":
        "https://risktransfer.egnyte.com/dl/LllqzeaNBe",
      "SAIC Waiver of Subrogation by State":
        "https://risktransfer.egnyte.com/dl/R6i69UdKKu",
      "SAIC - SNIC Policy Change Form	":
        "https://risktransfer.egnyte.com/dl/RgeDIUcSex",
    },
  },
  Certificates_Username_Thrive: {
    mainContent: "",
    SubHeading: "CERT EXCHANGE DOCUMENTS",
    Links: {
      Cert_Exchange_User_Guide: "https://risktransfer.egnyte.com/dl/L5cllYWIcb",
    },
  },
  Payroll_Reporting: {
    mainContent: "",
    SubHeading: "PREMIUM AUDIT AND BILLING",
    Links: {
      SAIC_Audit_Dispute_Form: "https://risktransfer.egnyte.com/dl/QqQ9KEt4jp",
      SAIC_Billing_Application_Guide:
        "https://risktransfer.egnyte.com/dl/mLjXL5Aw8y",
      SAIC_Collateral_Payment_and_Deductible_Billing_Guidelines:
        "https://risktransfer.egnyte.com/dl/WRfV2xvYI1",
      SAIC_Insured_Billing_Quick_Reference_Guide:
        "https://risktransfer.egnyte.com/dl/CwYGdgvVZq",
      SAIC_Payroll_Reporting_Guidelines:
        "https://risktransfer.egnyte.com/dl/y2hkDohYSR",
    },
  },
  Payroll_Reporting2: {
    mainContent: "",
    SubHeading: "PREMIUM AUDIT AND BILLING",
    Links: {
      AmTrust_Audit_Dispute_Form:
        "https://risktransfer.egnyte.com/dl/vu7N662kpP",
      "AmTrust_PSR_(PAYO_3rd_Party)_Flyer":
        "https://risktransfer.egnyte.com/dl/K8iZ1tEsJk",
      "AmTrust_PSR_(PAYO_Self_Reporting)_Flyer":
        "https://risktransfer.egnyte.com/dl/K4enCsHmCl",
      "AmTrust_PSR_(PAYO_Self_Reporting)_Insured_Registration_and_User_Guide":
        "https://risktransfer.egnyte.com/dl/Qiu0Qj7vB1",
      "AmTrust_PSR_(PAYO_Self_Reporting)_Subject_Wage_Explanation_Guidelines":
        "https://risktransfer.egnyte.com/dl/Ak8vVo0GBJ",
      "AmTrust_PSR_(PAYO_Self_Reporting)_Welcome_Email":
        "https://risktransfer.egnyte.com/dl/ztZFVAdYUI",
      "AmTrust_PSR_(PAYO_Three_PAY)_Plan_Solutions_Summary":
        "https://risktransfer.egnyte.com/dl/KyCtoTYMqJ",
      "AmTrust_PSR_(PAYO_Traditional)_Plan":
        "https://risktransfer.egnyte.com/dl/LcDjf5pQL0",
    },
  },
  Carrier_Claims_Resources: {
    mainContent: (
      <>
        SAIC/Broadspire Claims System:{" "}
        <a
          className={styles.ancorstyle}
          href="https://broadspireinview.com/login/Login.aspx"
          target="_blank"
        >
          login
        </a>{" "}
        <br />
        To file a claim call: 888 - 599 - 8726
        <br />
        Or email to:{" "}
        <a
          className={styles.ancorstyle}
          href="mailto:risktransfer@choosebroadspire.com"
          target="_blank"
        >
          risktransfer@choosebroadspire.com
        </a>
      </>
    ),
    SubHeading: "CARRIER CLAIMS DOCUMENTS",
    Links: {
      SAIC_Claims_Resource:
        "https://risktransfer.egnyte.com/dl/XBEpMsEiiW/Risk_Transfer_(RiskAware)_SAIC_-_SLIC_-_Broadspire_Claim_Resources.docx_",
      "Provider_Directory_Information_(SAIC)": "",
      Division_of_WC_Websites:
        "https://risktransfer.egnyte.com/dl/joL7j5knyJ/Division_of_WC_Websites.pdf_",
      "First_Report_of_Injury_-_SAIC":
        "https://risktransfer.egnyte.com/dl/Kkd0p3lxMJ/Risk_Transfer_-_SAIC_-_Broadspire_FROI_Spreadsheet_(Program_023972).xlsm_",
      "Pharmacy_First_Fill_(SAIC)":
        "https://risktransfer.egnyte.com/dl/aV0JRB5CjX/CadenceRx_Broadspire_FirstFill_Letter_FINAL_8.2020_ENGLISH.PDF_",
    },
  },
  Submission_Portal1: {
    mainContent:
      "Rates displayed on indications are carrier manual rates only and do not factor in policy-specific pricing components. Calculated indications are not bindable quotes. An appropriate submission is required.",
    SubHeading: "Submission Portal - AmTrust Temp Staffing",
    Links: {
      "ACORD 130(FL) Fillable_Blank":
        "https://risktransfer.egnyte.com/dl/r5IosCI0aT",
      "ACORD 130 Fillable_Blank":
        "https://risktransfer.egnyte.com/dl/f9G7rBfjck",
      "AmTrust Class Code Referral Form":
        "https://risktransfer.egnyte.com/dl/KJ6V8jMCDO",
      "AmTrust Endorsement Request Form-Staffing":
        "https://risktransfer.egnyte.com/dl/bNC7B0HNvg",
      "Amtrust Staffing Supplemental Application":
        "https://risktransfer.egnyte.com/dl/jl7XhXMi6C",
      "Medical Staffing Supplement":
        "https://risktransfer.egnyte.com/dl/Kc8gi06hMB",
    },
  },
};

const claimsObj = {
  Submission_Portal: {
    mainContent: (
      <>
        The i3 Analytics platform provides insightful analytic visualization of
        your workers’ compensation claims data to drive your risk management
        actions. For a demo contact us at{" "}
        <a className={styles.ancorstyle} href="mailto:info@i3analytics.com">
          info@i3analytics.com
        </a>
      </>
    ),
    SubHeading: "i3 ANALYTICS DOCUMENTS",
    Links: {
      i3_Analytics_Preview:
        "https://risktransfer.egnyte.com/dl/QgFkwxpDTj/i3_Analytics_for_PEOs_Marketing_2019.pdf_",
    },
  },
  Certificates_Username_Thrive: {
    mainContent: "",
    SubHeading: "",
    Links: {
      "Risk Transfer, RPS_SAIC_PMA (New Loss Reporting)":
        "https://risktransfer.egnyte.com/dl/hVoSVnsS3l",
      "Risk Transfer, RPS_SAIC_PMA Companies (Network Provider Search)":
        "https://risktransfer.egnyte.com/dl/5TtFnkoKeC",
      "PMA_Cadence_Rx-First-Fill-Card_English_1.2024":
        "https://risktransfer.egnyte.com/dl/kQD6qsI0OC",
      "Risk Transfer, RPS_SAIC_PMA_Generic_FROI_Spreadsheet":
        "https://risktransfer.egnyte.com/dl/i71Vaf9MUp",
      "Risk Transfer, RPS_SAIC-PMA_Claim_Team_Contact_Spreadsheet":
        "https://risktransfer.egnyte.com/dl/nkTO6YEyuZ",
    },
  },
  Carrier_Claims_Resources: {
    mainContent: <></>,
    SubHeading: "",
    Links: {
      AmTrust_New_Loss_Reporting:
        "https://risktransfer.egnyte.com/dl/JSOmNDuVOF",
      "AmTrust_Claims_Kit_FAQ-Provider_Directory_Searches,Medical_Panels":
        "https://risktransfer.egnyte.com/dl/ULXWZY64aq",
      "AmTrust_Pharmacy_First_Fill_(Optum)":
        "https://risktransfer.egnyte.com/dl/FP8LGY5O0O",
      "AmTrust_WC_FAQ_RX_First_Fill, _Claim_Handler_Lookup":
        "https://risktransfer.egnyte.com/dl/3kK6bHW0rT",
      "AmTrust_Nurse_Triage-AmCares_Info-Sheet":
        "https://risktransfer.egnyte.com/dl/5sPC8MgHwz",
      AmTrust_Customer_Service_Contacts:
        "https://risktransfer.egnyte.com/dl/qOJ3TzVnxk",
      AmTrust_Registration_For_Insured_Portal:
        "https://risktransfer.egnyte.com/dl/r6aPzwRMZx",
    },
  },
  Carrier_Claims_Resources1: {
    mainContent: <></>,
    SubHeading: "",
    Links: {
      "Risk Transfer, RPS_SAIC_Broadspire (New Loss Reporting)":
        "https://risktransfer.egnyte.com/dl/Esdf9Iz5uG",
      "Risk Transfer, RPS_SAIC_Broadspire (Medical Treatment Resources)":
        "https://risktransfer.egnyte.com/dl/7PLkRnfcwy",
      "Risk Transfer, RPS_SAIC_Broadspire (Claim System Access and Loss Runs)":
        "https://risktransfer.egnyte.com/dl/vLA1XHdNfo",
      "SAIC_Broadspire Claims Team Contact List":
        "https://risktransfer.egnyte.com/dl/ASc2RhLBN1",
      "Risk Transfer - SAIC - Broadspire Generic FROI Spreadsheet (Program 023972)":
        "https://risktransfer.egnyte.com/dl/lUCVAU4d23",
      "Broadspire MyMatrixx FirstFill Sample - English Efft 10-16-22":
        "https://risktransfer.egnyte.com/dl/SHZP24I97R",
      "Broadspire MyMatrixx FirstFill Sample - Spanish Efft 10-16-22":
        "https://risktransfer.egnyte.com/dl/lgTEu2o0If",
    },
  },
};

const Loss_Prevention = {
  Submission_Portal: {
    mainContent: <></>,

    SubHeading: "",
    Links: {
      "AmTrust Safety Resources": "https://amtrustfinancial.com/loss-control",
      "SAIC Safety Resources": "	https://www.serviceinsurance.com/risk-control/",
    },
  },
  Certificates_Username_Thrive: {
    mainContent:
      "Zywave learing offers over 180 on-line courses supported by a simple web-based Learning Management System to help you not only provide great safety and HR content, but to administer and track training assignments.",
    SubHeading: "ZYWAVE DOCUMENTS",
    Links: {
      Course_List:
        "https://risktransfer.egnyte.com/dl/8o99czq5p6/ZywaveLearning_ForClient_Course_List_5_27_20.pdf_",
    },
  },
  Payroll_Reporting: {
    mainContent: <></>,
    SubHeading: "",
    Links: {
      "SAIC Safety Video Access Request":
        "https://www.serviceinsurance.com/risk-control-resource-center/",
      "AmTrust Safety Video Access Request":
        "https://amtrustfinancial.com/loss-control/streaming-videos/streaming-videos-access",
    },
  },
  Carrier_Claims_Resources: {
    mainContent: (
      <>
        Human Resources is directly connected with workers’ compensation as
        injured workers are employees. This center contains resources to help
        you manage the related HR components to the Workers’ Compensation safety
        and injury management process.
      </>
    ),
    SubHeading: "",
    Links: {},
  },
  Worker_Comp_Resources: {
    mainContent: (
      <>
        Understanding your workers’ compensation program is key to expense
        management. This resource center links you to workers’ compensation
        specific resources from experience mods to return to work programs.
      </>
    ),
    SubHeading: "",
    Links: {},
  },
  Workzone_Order_Loss_Control: {
    mainContent: (
      <>To learn more about this product email us at contact@riskawareinc.com</>
    ),
    SubHeading: "",
    Links: {},
  },
};

const i3dashboard = {
  Submission_Portal: {
    mainContent: (
      <>
        The i3 Analytics platform provides insightful analytic visualization of
        your workers’ compensation claims data to drive your risk management
        actions. For a demo contact us at{" "}
        <a
          className={styles.ancorstyle}
          href="mailto:RPS.Orlando-2.info@rpsins.com"
        >
          RPS.Orlando-2.info@rpsins.com
        </a>
      </>
    ),
    SubHeading: "i3 ANALYTICS DOCUMENTS",
    Links: {
      i3_Analytics_Preview:
        "https://risktransfer.egnyte.com/dl/QgFkwxpDTj/i3_Analytics_for_PEOs_Marketing_2019.pdf_",
    },
  },
};

const HomePage = (props) => {
  const {
    updatePopUpData,
    updateCurrentFormStage,
    updateMailCampaignUser,
    sessionData,
    formStage,
    getQuoteClicked,
  } = props;
  const [showTab, setShowTab] = useState(false);
  const [isLoggedIn, setLoggedInStatus] = useState(false);
  const [group, setGroup] = useState([]);
  const [userGroups, setuserGroups] = useState([]);
  const [emailID, setemailID] = useState("");
  const [isDemoLogin, setIsDemoLogin] = useState(false);
  const [isUwLogin, setIsUwLogin] = useState(false);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 900);
  const [activeTab, setActiveTab] = useState("Policy");
  const [activeTabSub, setActiveTabSub] = useState("tab1");
  const [brokerLoginCheck, setBrokerLoginCheck] = useState(false);
  const [peoLoginCheck, setPeoLoginCheck] = useState(false);
  const [brokerlossRunCheck, setBrokerlossRunCheck] = useState(false);
  const [submissionPortalSAICCheck, setSubmissionPortalSAICCheck] =
    useState(false);
  const [submissionPortalAmtrustCheck, setSubmissionPortalAmtrustCheck] =
    useState(false);
  const [certificatesCheck, setCeritificateCheck] = useState(false);
  const [deductibleSAICCheck, setDeductibleSAICCheck] = useState(false);
  const [deductibleAmtrustCheck, setDeductibleAmtrustCheck] = useState(false);
  const [claimsCheck, setClaimsCheck] = useState(false);
  const [lossPreventionCheck, setLossPreventionCheck] = useState(false);
  const [powerbiAnalyticsCheck, setPowerbiAnalyticsCheck] = useState(false);
  const [carrierCliamsCheck, setCarrierClaimsCheck] = useState(false);
  const [allAccess, setAllaccess] = useState(false);
  const [brokerlossrunLink, setBrokerLossRunLInk] = useState();
  const [amtrustClaimsCheck, setAmtrustClaimsCheck] = useState(false);
  const [pmaCheck, setPmaCheck] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTabSub(tab);
  };

  useEffect(() => {
    async function authentication() {
      try {
        let email = sessionData?.attributes?.email?.split("@")[0];
        let permissionList =
          sessionData?.attributes?.["custom:permissions"]?.split(",") || [];
        setemailID(email);
        if (sessionData?.attributes?.["custom:brokerLossRunLink"]) {
          setBrokerLossRunLInk(
            sessionData?.attributes?.["custom:brokerLossRunLink"]
          );
        }
        let userGroups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (userGroups?.includes(process.env.REACT_APP_DEMO_GROUP)) {
          setIsDemoLogin(true);
        }
        if (userGroups?.includes(process.env.REACT_APP_PEO_GROUP)) {
          setPeoLoginCheck(true);
        }
        if (userGroups?.includes(process.env.REACT_APP_ALLACCESS)) {
          setAllaccess(true);
        }
        if (userGroups?.includes(process.env.REACT_APP_BROKER)) {
          setBrokerLoginCheck(true);
        }

        if (
          permissionList.includes("brokerlossrun") &&
          userGroups?.includes(process.env.REACT_APP_BROKER)
        ) {
          setBrokerlossRunCheck(true);
        }

        if (
          permissionList.includes("deductible-saic") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setDeductibleSAICCheck(true);
          setActiveTabSub("tab3");
        }
        if (
          permissionList.includes("deductible-amtrust") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setDeductibleAmtrustCheck(true);
          setActiveTabSub("tab5");
        }
        if (
          permissionList.includes("cliams") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setClaimsCheck(true);
        }

        if (
          permissionList.includes("certificate") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setCeritificateCheck(true);
          setActiveTabSub("tab2");
        }

        if (
          permissionList.includes("policy-amtrust") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setSubmissionPortalAmtrustCheck(true);
          setActiveTabSub("tab4");
        }

        if (
          permissionList.includes("policy-saic") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setSubmissionPortalSAICCheck(true);
          setActiveTabSub("tab1");
        }


        if (
          permissionList.includes("loss") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setLossPreventionCheck(true);
          if (activeTab === "Loss Prevention") {
            setActiveTabSub("tab1");
          }
        }
        if (
          permissionList.includes("powerbi") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setPowerbiAnalyticsCheck(true);
          if (activeTab === "i3 Analytics Dashboard") {
            setActiveTabSub("tab1");
          }
        }

        if (
          permissionList.includes("amtrust") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setAmtrustClaimsCheck(true);
          if (activeTab === "Claims") {
            setActiveTabSub("tab3");
          }
        }
        if (
          permissionList.includes("pma") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setPmaCheck(true);
          if (activeTab === "Claims") {
            setActiveTabSub("tab2");
          }
        }

        if (
          permissionList.includes("carrier") &&
          (userGroups?.includes(process.env.REACT_APP_BROKER) ||
            userGroups?.includes(process.env.REACT_APP_PEO_GROUP))
        ) {
          setCarrierClaimsCheck(true);
          if (activeTab === "Claims") {
            setActiveTabSub("tab1");
          }
        }



        setuserGroups({ userGroups });
        console.log("emailID: ", emailID, userGroups);
      } catch (error) {
        console.log("comp did update err", error);
      }

      if (emailID) {
        setuserGroups({ emailID });
      }
    }

    authentication();
  }, [sessionData, emailID, activeTab]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 900);
    };
    let permissionList =
      sessionData?.attributes?.["custom:permissions"]?.split(",") || [];

    if (permissionList.includes("no-policy")) {
      setActiveTab("Claims");
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderCardContent = () => {
    switch (activeTabSub) {
      case "tab1":
        return (
          <div>
            <p>{policyObj.Submission_Portal.mainContent}</p>
            <hr />
            <div className={styles.subhead}>
              {policyObj.Submission_Portal.SubHeading}
            </div>
            <ul>
              {Object.entries(policyObj.Submission_Portal.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            <hr />
          </div>
        );
      case "tab2":
        return (
          <div>
            <p>{policyObj.Certificates_Username_Thrive.mainContent}</p>
            <hr />
            <div className={styles.subhead}>
              {policyObj.Certificates_Username_Thrive.SubHeading}
            </div>
            <ul>
              {Object.entries(policyObj.Certificates_Username_Thrive.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            <hr />
          </div>
        );
      case "tab3":
        return (
          <div>
            <p>{policyObj.Payroll_Reporting.mainContent}</p>
            <hr />
            <div className={styles.subhead}>
              {policyObj.Payroll_Reporting.SubHeading}
            </div>
            <ul>
              {Object.entries(policyObj.Payroll_Reporting.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            <hr />
          </div>
        );
      case "tab4":
        return (
          <div>
            <p>{policyObj.Submission_Portal1.mainContent}</p>
            <hr />
            <div className={styles.subhead}>
              {policyObj.Submission_Portal1.SubHeading}
            </div>
            <ul>
              {Object.entries(policyObj.Submission_Portal1.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            <hr />
          </div>
        );
      case "tab5":
        return (
          <div>
            <p>{policyObj.Payroll_Reporting2.mainContent}</p>
            <hr />
            <div className={styles.subhead}>
              {policyObj.Payroll_Reporting2.SubHeading}
            </div>
            <ul>
              {Object.entries(policyObj.Payroll_Reporting2.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            <hr />
          </div>
        );
      default:
        return <p>Select a tab to see content.</p>;
    }
  };
  const renderCardContent2 = () => {
    switch (activeTabSub) {
      case "tab1":
        return (
          <div className={styles.infoBox}>
            <p>{claimsObj.Carrier_Claims_Resources1.mainContent}</p>
            <hr />
            <div className={styles.subhead}>
              {claimsObj.Carrier_Claims_Resources1.SubHeading}
            </div>
            <ul>
              {Object.entries(claimsObj.Carrier_Claims_Resources1.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            <hr />
          </div>
        );
      case "tab2":
        return (
          <div className={styles.infoBox}>
            <p>{claimsObj.Certificates_Username_Thrive.mainContent}</p>
            <hr />
            <div className={styles.subhead}>
              {claimsObj.Certificates_Username_Thrive.SubHeading}
            </div>
            <ul>
              {Object.entries(claimsObj.Certificates_Username_Thrive.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            <hr />
          </div>
        );
      case "tab3":
        return (
          <div className={styles.infoBox}>
            <p>{claimsObj.Carrier_Claims_Resources.mainContent}</p>
            <hr />
            <div className={styles.subhead}>
              {claimsObj.Carrier_Claims_Resources.SubHeading}
            </div>
            <ul>
              {Object.entries(claimsObj.Carrier_Claims_Resources.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            <hr />
          </div>
        );
      case "tab4":
        return "";

      default:
        return <p>Select a tab to see content.</p>;
    }
  };
  const renderCardContent3 = () => {
    switch (activeTabSub) {
      case "tab1":
        return (
          <div>
            <p>{Loss_Prevention.Submission_Portal.mainContent}</p>
            {Object.keys(Loss_Prevention.Submission_Portal.Links).length >
              0 && <hr />}

            <div className={styles.subhead}>
              {Loss_Prevention.Submission_Portal.SubHeading}
            </div>
            <ul>
              {Object.entries(Loss_Prevention.Submission_Portal.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            {Object.keys(Loss_Prevention.Submission_Portal.Links).length >
              0 && <hr />}
          </div>
        );
      case "tab2":
        return (
          <div>
            <p>{Loss_Prevention.Certificates_Username_Thrive.mainContent}</p>
            {Object.keys(Loss_Prevention.Certificates_Username_Thrive.Links)
              .length > 0 && <hr />}
            <div className={styles.subhead}>
              {Loss_Prevention.Certificates_Username_Thrive.SubHeading}
            </div>
            <ul>
              {Object.entries(
                Loss_Prevention.Certificates_Username_Thrive.Links
              ).map(([key, link]) => (
                <li key={key}>
                  <a target="_blank" href={link}>
                    {key.replace(/_/g, " ")}
                  </a>
                </li>
              ))}
            </ul>
            {Object.keys(Loss_Prevention.Certificates_Username_Thrive.Links)
              .length > 0 && <hr />}
          </div>
        );
      case "tab3":
        return (
          <div>
            <p>{Loss_Prevention.Payroll_Reporting.mainContent}</p>
            {Object.keys(Loss_Prevention.Payroll_Reporting.Links).length >
              0 && <hr />}
            <div className={styles.subhead}>
              {Loss_Prevention.Payroll_Reporting.SubHeading}
            </div>
            <ul>
              {Object.entries(Loss_Prevention.Payroll_Reporting.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            {Object.keys(Loss_Prevention.Payroll_Reporting.Links).length >
              0 && <hr />}
          </div>
        );
      case "tab4":
        return (
          <div>
            <p>{Loss_Prevention.Carrier_Claims_Resources.mainContent}</p>
            {Object.keys(Loss_Prevention.Carrier_Claims_Resources.Links)
              .length > 0 && <hr />}

            <div className={styles.subhead}>
              {Loss_Prevention.Carrier_Claims_Resources.SubHeading}
            </div>
            <ul>
              {Object.entries(
                Loss_Prevention.Carrier_Claims_Resources.Links
              ).map(([key, link]) => (
                <li key={key}>
                  <a target="_blank" href={link}>
                    {key.replace(/_/g, " ")}
                  </a>
                </li>
              ))}
            </ul>
            {Object.keys(Loss_Prevention.Carrier_Claims_Resources.Links)
              .length > 0 && <hr />}
          </div>
        );
      case "tab5":
        return (
          <div>
            <p>{Loss_Prevention.Worker_Comp_Resources.mainContent}</p>
            {Object.keys(Loss_Prevention.Worker_Comp_Resources.Links).length >
              0 && <hr />}

            <div className={styles.subhead}>
              {Loss_Prevention.Worker_Comp_Resources.SubHeading}
            </div>
            <ul>
              {Object.entries(Loss_Prevention.Worker_Comp_Resources.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            {Object.keys(Loss_Prevention.Worker_Comp_Resources.Links).length >
              0 && <hr />}
          </div>
        );
      case "tab6":
        return (
          <div>
            <p>{Loss_Prevention.Workzone_Order_Loss_Control.mainContent}</p>
            {Object.keys(Loss_Prevention.Workzone_Order_Loss_Control.Links)
              .length > 0 && <hr />}
            <div className={styles.subhead}>
              {Loss_Prevention.Workzone_Order_Loss_Control.SubHeading}
            </div>
            <ul>
              {Object.entries(
                Loss_Prevention.Workzone_Order_Loss_Control.Links
              ).map(([key, link]) => (
                <li key={key}>
                  <a target="_blank" href={link}>
                    {key.replace(/_/g, " ")}
                  </a>
                </li>
              ))}
            </ul>
            {Object.keys(Loss_Prevention.Workzone_Order_Loss_Control.Links)
              .length > 0 && <hr />}
          </div>
        );
      default:
        return <p>Select a tab to see content.</p>;
    }
  };
  const renderCardContent4 = () => {
    switch (activeTabSub) {
      case "tab1":
        return (
          <div>
            <p>{i3dashboard.Submission_Portal.mainContent}</p>
            <hr />
            <div className={styles.subhead}>
              {claimsObj.Submission_Portal.SubHeading}
            </div>
            <ul>
              {Object.entries(i3dashboard.Submission_Portal.Links).map(
                ([key, link]) => (
                  <li key={key}>
                    <a target="_blank" href={link}>
                      {key.replace(/_/g, " ")}
                    </a>
                  </li>
                )
              )}
            </ul>
            <hr />
          </div>
        );

      default:
        return <p>Select a tab to see content.</p>;
    }
  };
  return (
    <div>
      {isMobileView ? (
        <nav className="navwidth newquote-sidebar bg-transparent">
          <input
            type="checkbox"
            id="menu"
            name="menu"
            className="m-menu__checkbox bg-white"
          />
          <label className="m-menu__toggle" htmlFor="menu">
            <svg
              width="35"
              height="35"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="butt"
              strokeLinejoin="arcs"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
          </label>
          <label className="m-menu__overlay" htmlFor="menu"></label>

          <div className="m-menu">
            <div className="m-menu__header">
              <label className="m-menu__toggle" htmlFor="menu">
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="butt"
                  strokeLinejoin="arcs"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </label>
              <span></span>
            </div>
            <Nav.Item>
              <Nav.Link className="sidebar-heading">
                <div className="d-flex align-items-center text-white pt-3">
                  <span className="LoginUser">{emailID}</span>
                  <span>
                    {isMobileView ? (
                      <li className="no-bullets">
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            logout();
                          }}
                        >
                          Logout <i className="bi-person-fill"></i>
                        </a>
                      </li>
                    ) : (
                      " "
                    )}
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>

            <hr
              style={{
                width: "90%",
                backgroundColor: "grey",
              }}
            />

            {isMobileView ? (
              <div>
                <a
                  className="dashboard-link-mobile text-white"
                  href={"/Dashboard"}
                >
                  Dashboard
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </nav>
      ) : (
        <div className={`d-flex bg-light ${styles.dashboardHeader}`}>
          <Header sessionData={sessionData} showTab={showTab} />
        </div>
      )}

      <div className={styles.tabStyle}>
        <div className={styles.alignCenterbtn}>
          {(certificatesCheck ||
            allAccess ||
            submissionPortalAmtrustCheck ||
            submissionPortalSAICCheck ||
            deductibleAmtrustCheck ||
            deductibleSAICCheck) && (
              <div className={styles.alignbtn}>
                <button
                  className={`${styles.button} ${activeTab === "Policy" ? styles.active : ""
                    }`}
                  onClick={() => {
                    setActiveTab("Policy");
                    setActiveTabSub("tab1"); // Reset activeTabSub to tab1
                  }}
                >
                  Policy
                </button>
                {activeTab === "Policy" && (
                  <div className={styles.imgstyle}>
                    <img src={vector} alt="Policy Indicator" />
                  </div>
                )}
              </div>
            )}

          {(claimsCheck || allAccess) && (
            <div className={styles.alignbtn}>
              <button
                className={`${styles.button} ${activeTab === "Claims" ? styles.active : ""
                  }`}
                onClick={() => {
                  setActiveTab("Claims");
                  setActiveTabSub("tab1"); // Reset activeTabSub to tab1
                }}
              >
                Claims
              </button>
              {activeTab === "Claims" && (
                <div className={styles.imgstyle}>
                  <img src={vector} alt="Claims Indicator" />
                </div>
              )}
            </div>
          )}

          {(lossPreventionCheck || allAccess) && (
            <div className={styles.alignbtn}>
              <button
                className={`${styles.button} ${activeTab === "Loss Prevention" ? styles.active : ""
                  }`}
                onClick={() => {
                  setActiveTab("Loss Prevention");
                  setActiveTabSub("tab1"); // Reset activeTabSub to tab1
                }}
              >
                Loss Prevention
              </button>
              {activeTab === "Loss Prevention" && (
                <div className={styles.imgstyle}>
                  <img src={vector} alt="Loss Prevention Indicator" />
                </div>
              )}
            </div>
          )}

          {(powerbiAnalyticsCheck || allAccess) && (
            <div className={styles.alignbtn}>
              <button
                className={`${styles.button} ${activeTab === "i3 Analytics Dashboard" ? styles.active : ""
                  }`}
                onClick={() => {
                  setActiveTab("i3 Analytics Dashboard");
                  setActiveTabSub("tab1"); // Reset activeTabSub to tab1
                }}
              >
                i3 Analytics, Power BI
              </button>
              {activeTab === "i3 Analytics Dashboard" && (
                <div className={styles.imgstyle}>
                  <img src={vector} alt="Loss Prevention Indicator" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {activeTab === "Policy" && (
        <div className={styles.container}>
          <div className={styles.leftSection}>
            <div className={styles.cardContainer}>
              {(submissionPortalSAICCheck || allAccess) && (
                <div
                  className={`${styles.card} ${activeTabSub === "tab1"
                      ? styles.cardActive
                      : styles.cardHeader1
                    }`}
                  onClick={() => handleTabClick("tab1")}
                >
                  <span>Submission Portal - SAIC </span>
                  <a href="/dashboard" target="_blank">
                    <div
                      className={`${styles.iconContainer} ${activeTabSub === "tab1"
                          ? styles.iconContainer1Active
                          : styles.iconContainerInactive
                        }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                          activeTabSub === "tab1"
                            ? styles.arrowActive
                            : styles.arrow
                        }
                      >
                        {" "}
                        <path d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z" />
                      </svg>
                    </div>
                  </a>
                </div>
              )}
              {(submissionPortalAmtrustCheck || allAccess) && (
                <div
                  className={`${styles.card} ${activeTabSub === "tab4"
                      ? styles.cardActive
                      : styles.cardHeader1
                    }`}
                  onClick={() => handleTabClick("tab4")}
                >
                  <span>Submission Portal - AmTrust Temp Staffing</span>
                  <div
                    className={`${styles.iconContainer} ${activeTabSub === "tab4"
                        ? styles.iconContainernoArrowActive
                        : styles.iconContainernoArrowInactive
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        activeTabSub === "tab4"
                          ? styles.noarrowActive
                          : styles.noarrow
                      }
                    >
                      <path
                        // className={
                        //   activeTab === "tab2" ? styles.arrowActive : styles.arrow
                        // }
                        d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z"
                      />
                    </svg>
                  </div>
                </div>
              )}
              {(certificatesCheck || allAccess) && (
                <div
                  className={`${styles.card} ${activeTabSub === "tab2"
                      ? styles.cardActive
                      : styles.cardHeader1
                    }`}
                  onClick={() => handleTabClick("tab2")}
                >
                  <span>Certificates </span>
                  <a
                    href="https://www.certificateexchange.com/login.jsp"
                    target="_blank"
                  >
                    <div
                      className={`${styles.iconContainer} ${activeTabSub === "tab2"
                          ? styles.iconContainerActive
                          : styles.iconContainerInactive
                        }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                          activeTabSub === "tab2"
                            ? styles.arrowActive
                            : styles.arrow
                        }
                      >
                        <path
                          // className={
                          //   activeTab === "tab2" ? styles.arrowActive : styles.arrow
                          // }
                          d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              )}
              {(deductibleSAICCheck || allAccess) && (
                <div
                  className={`${styles.card} ${activeTabSub === "tab3"
                      ? styles.cardActive
                      : styles.cardHeader1
                    }`}
                  onClick={() => handleTabClick("tab3")}
                >
                  <span>
                    Payroll Reporting Premium & Deductible Billing Premium Audit
                    - SAIC
                  </span>
                  <div
                    className={`${styles.iconContainer} ${activeTabSub === "tab3"
                        ? styles.iconContainernoArrowActive
                        : styles.iconContainernoArrowInactive
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        activeTabSub === "tab3"
                          ? styles.noarrowActive
                          : styles.noarrow
                      }
                    >
                      <path
                        // className={
                        //   activeTab === "tab2" ? styles.arrowActive : styles.arrow
                        // }
                        d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z"
                      />
                    </svg>
                  </div>
                </div>
              )}
              {(deductibleAmtrustCheck || allAccess) && (
                <div
                  className={`${styles.card} ${activeTabSub === "tab5"
                      ? styles.cardActive
                      : styles.cardHeader1
                    }`}
                  onClick={() => handleTabClick("tab5")}
                >
                  <span>
                    Payroll Reporting Premium & Deductible Billing Premium Audit
                    - Amtrust
                  </span>
                  <div
                    className={`${styles.iconContainer} ${activeTabSub === "tab5"
                        ? styles.iconContainernoArrowActive
                        : styles.iconContainernoArrowInactive
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        activeTabSub === "tab5"
                          ? styles.noarrowActive
                          : styles.noarrow
                      }
                    >
                      <path
                        // className={
                        //   activeTab === "tab2" ? styles.arrowActive : styles.arrow
                        // }
                        d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.rightSection}>
            <div className={styles.infoBox}>{renderCardContent()}</div>
          </div>
        </div>
      )}

      {activeTab === "Claims" && (
        <div className={styles.container}>
          <div className={styles.leftSection}>
            <div className={styles.cardContainer}>
              {(carrierCliamsCheck || allAccess) && (
                <div
                  className={`${styles.card} ${activeTabSub === "tab1"
                      ? styles.cardActive
                      : styles.cardHeader1
                    }`}
                  onClick={() => handleTabClick("tab1")}
                >
                  <span>Service American - Broadspire "InView"</span>
                  <a
                    href="https://broadspireinview.com/login/Login.aspx"
                    target="_blank"
                  >
                    <div
                      className={`${styles.iconContainer} ${activeTabSub === "tab1"
                          ? styles.iconContainerActive
                          : styles.iconContainerInactive
                        }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                          activeTabSub === "tab1"
                            ? styles.arrowActive
                            : styles.arrow
                        }
                      >
                        <path d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z" />
                      </svg>
                    </div>
                  </a>
                </div>
              )}

              {(pmaCheck || allAccess) && (
                <div
                  className={`${styles.card} ${activeTabSub === "tab2"
                      ? styles.cardActive
                      : styles.cardHeader1
                    }`}
                  onClick={() => handleTabClick("tab2")}
                >
                  <span>Service American - PMA "Cinch"</span>
                  <a
                    href="https://pmacinchsetup.pmagroup.com/app/citrixnetscalergateway_saml/exk6t1ta0urzMZFF0697/sso/saml"
                    target="_blank"
                  >
                    <div
                      className={`${styles.iconContainer} ${activeTabSub === "tab2"
                          ? styles.iconContainerActive
                          : styles.iconContainerInactive
                        }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                          activeTabSub === "tab2"
                            ? styles.arrowActive
                            : styles.arrow
                        }
                      >
                        <path d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z" />
                      </svg>
                    </div>
                  </a>
                </div>
              )}

              {(amtrustClaimsCheck || allAccess) && (
                <div
                  className={`${styles.card} ${activeTabSub === "tab3"
                      ? styles.cardActive
                      : styles.cardHeader1
                    }`}
                  onClick={() => handleTabClick("tab3")}
                >
                  <span> Amtrust</span>
                  <a href="https://amtrustfinancial.com/login" target="_blank">
                    <div
                      className={`${styles.iconContainer} ${activeTabSub === "tab3"
                          ? styles.iconContainerActive
                          : styles.iconContainerInactive
                        }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                          activeTabSub === "tab3"
                            ? styles.arrowActive
                            : styles.arrow
                        }
                      >
                        <path d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z" />
                      </svg>
                    </div>
                  </a>
                </div>
              )}

              {brokerlossrunLink && (
                <div
                  className={`${styles.card} ${activeTabSub === "tab4"
                      ? styles.cardActive
                      : styles.cardHeader1
                    }`}
                  onClick={() => handleTabClick("tab4")}
                >
                  <span> Broker Loss</span>
                  <a href={brokerlossrunLink} target="_blank">
                    <div
                      className={`${styles.iconContainer} ${activeTabSub === "tab4"
                          ? styles.iconContainerActive
                          : styles.iconContainerInactive
                        }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={
                          activeTabSub === "tab4"
                            ? styles.arrowActive
                            : styles.arrow
                        }
                      >
                        <path d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z" />
                      </svg>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>

          <div className={styles.rightSection}>
            <div>{renderCardContent2()}</div>
          </div>
        </div>
      )}

      {activeTab === "Loss Prevention" && (
        <div className={styles.container}>
          <div className={styles.leftSection}>
            <div className={styles.cardContainer}>
              <div
                className={`${styles.card} ${activeTabSub === "tab1"
                    ? styles.cardActive
                    : styles.cardHeader1
                  }`}
                onClick={() => handleTabClick("tab1")}
              >
                <span>Safety Resources</span>
                {/* <a
                  href="https://portal.i3analytics.com/safety-resoures/"
                  target="_blank"
                >
                  <div
                    className={`${styles.iconContainer} ${
                      activeTabSub === "tab1"
                        ? styles.iconContainerActive
                        : styles.iconContainerInactive
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        activeTabSub === "tab1"
                          ? styles.arrowActive
                          : styles.arrow
                      }
                    >
                      <path d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z" />
                    </svg>
                  </div>
                </a> */}
              </div>
              {/* 
              <div
                className={`${styles.card} ${activeTabSub === "tab2"
                  ? styles.cardActive
                  : styles.cardHeader1
                  }`}
                onClick={() => handleTabClick("tab2")}
              >
                <span>Zywave Learning</span>
                <div
                  className={`${styles.iconContainer} ${activeTabSub === "tab2"
                    ? styles.iconContainernoArrowActive
                    : styles.iconContainernoArrowInactive
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={
                      activeTabSub === "tab2"
                        ? styles.noarrowActive
                        : styles.noarrow
                    }
                  >
                    <path
                      // className={
                      //   activeTab === "tab2" ? styles.arrowActive : styles.arrow
                      // }
                      d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z"
                    />
                  </svg>
                </div>
              </div> */}

              <div
                className={`${styles.card} ${activeTabSub === "tab3"
                    ? styles.cardActive
                    : styles.cardHeader1
                  }`}
                onClick={() => handleTabClick("tab3")}
              >
                <span> Safety Video</span>

                {/* <div
                  className={`${styles.iconContainer} ${
                    activeTabSub === "tab3"
                      ? styles.iconContainerActive
                      : styles.iconContainerInactive
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={
                      activeTabSub === "tab3"
                        ? styles.arrowActive
                        : styles.arrow
                    }
                  >
                    <path
                      // className={
                      //   activeTab === "tab2" ? styles.arrowActive : styles.arrow
                      // }
                      d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z"
                    />
                  </svg>
                </div> */}
              </div>

              <div
                className={`${styles.card} ${activeTabSub === "tab4"
                    ? styles.cardActive
                    : styles.cardHeader1
                  }`}
                onClick={() => handleTabClick("tab4")}
              >
                <span>HR Resources</span>
                <a href="./resources" target="_blank">
                  <div
                    className={`${styles.iconContainer} ${activeTabSub === "tab4"
                        ? styles.iconContainerActive
                        : styles.iconContainerInactive
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        activeTabSub === "tab4"
                          ? styles.arrowActive
                          : styles.arrow
                      }
                    >
                      <path
                        // className={
                        //   activeTab === "tab4" ? styles.arrowActive : styles.arrow
                        // }
                        d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z"
                      />
                    </svg>
                  </div>
                </a>
              </div>
              <div
                className={`${styles.card} ${activeTabSub === "tab5"
                    ? styles.cardActive
                    : styles.cardHeader1
                  }`}
                onClick={() => handleTabClick("tab5")}
              >
                <span>Worker's Comp Resources</span>
                <a href="./workers" target="_blank">
                  <div
                    className={`${styles.iconContainer} ${activeTabSub === "tab5"
                        ? styles.iconContainerActive
                        : styles.iconContainerInactive
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        activeTabSub === "tab5"
                          ? styles.arrowActive
                          : styles.arrow
                      }
                    >
                      <path
                        // className={
                        //   activeTab === "tab4" ? styles.arrowActive : styles.arrow
                        // }
                        d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z"
                      />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className={styles.rightSection}>
            <div className={styles.infoBox}>{renderCardContent3()}</div>
          </div>
        </div>
      )}

      {activeTab === "i3 Analytics Dashboard" && (
        <div className={styles.container}>
          <div className={styles.leftSection}>
            <div className={styles.cardContainer}>
              <div
                className={`${styles.card} ${activeTabSub === "tab1"
                    ? styles.cardActive
                    : styles.cardHeader1
                  }`}
                onClick={() => handleTabClick("tab1")}
              >
                <span>i3 Analytics Dashboard</span>
                <a
                  href="https://app.powerbi.com/groups/494cf519-3262-4a32-89f9-dd39a9be6b01/reports/30374f2c-4b9b-43bf-9fa1-e795daec4ea2?ctid=6cacd170-f897-4b19-ac58-46a23307b80a&pbi_source=linkShare"
                  target="_blank"
                >
                  <div
                    className={`${styles.iconContainer} ${activeTabSub === "tab1"
                        ? styles.iconContainerActive
                        : styles.iconContainerInactive
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={
                        activeTabSub === "tab1"
                          ? styles.arrowActive
                          : styles.arrow
                      }
                    >
                      <path d="M1.30633 23.1207C0.671714 23.6532 0.588938 24.5993 1.12144 25.234C1.65394 25.8686 2.60008 25.9513 3.23469 25.4188L1.30633 23.1207ZM29.0443 3.18852C29.1165 2.36324 28.506 1.63569 27.6807 1.56349L14.2321 0.386889C13.4068 0.314687 12.6793 0.925172 12.6071 1.75045C12.5349 2.57572 13.1453 3.30327 13.9706 3.37547L25.9249 4.42134L24.8791 16.3757C24.8069 17.201 25.4174 17.9285 26.2426 18.0007C27.0679 18.0729 27.7955 17.4624 27.8677 16.6371L29.0443 3.18852ZM3.23469 25.4188L28.5142 4.20685L26.5858 1.90872L1.30633 23.1207L3.23469 25.4188Z" />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className={styles.rightSection}>
            <div className={styles.infoBox}>{renderCardContent4()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
